<template>
  <div>
    <section class="container-fluid setcion_img">
      <div class="container">
        <div class="row pt-5">
          <div class="col-md-6 text-start mt-5 pt-5 title_text">
            <h1 class="text-white text-uppercase">
              FAQ
            </h1>
            <p class="mt-5 pt-2">Last Updated: 21 January 2020</p>
            <p class="text-white mt-5">
              Lobortis enim porta nisl nisl mauris. Non quam commodo feugiat
              dictum pharetra enim, quis. Sed tempor, vitae ullamcorper viverra
              massa at. Fermentum urna scelerisque rhoncus euismod. Cras mi quis
              odio vel commodo tellus maecenas.
            </p>
          </div>
        </div>
        <!-- <div class="row mt-5 pt-5">
          <p class="text-center mt-5 pt-5 cursor_pointer">Scroll down</p>
        </div> -->
      </div>
    </section>
    <img src="@assets/img/bg-faq.png" class="img_bg position-absolute" alt="" />
    <section class="container definitions mt-5 ">
      <div class="row">
        <div class="col p-0 dropdown_wraper">
          <b-card no-body class="mb-5" v-for="item in faq" :key="item.id">
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
              @click="setCheck(item.id)"
            >
              <b-button block v-b-toggle="'acordeon' + item.id" variant="info">
                <div class="button_content">
                  <div class="d-md-block">
                    <img src="@assets/img/quote.png" alt="" />
                  </div>
                  <p>
                    {{ item.title.en }}
                  </p>
                  <b-icon
                    icon="chevron-compact-down"
                    v-if="faqs_check_obj && !faqs_check_obj[item.id]"
                  />
                  <b-icon icon="chevron-compact-up" v-else />
                </div>
                <b-collapse
                  :id="'acordeon' + item.id"
                  :accordion="'acordeon' + item.id"
                  role="tabpanel"
                >
                  <b-card-body class="w-100">
                    <b-card-text class="text-white">{{
                      item.description.en
                    }}</b-card-text>
                  </b-card-body>
                </b-collapse></b-button
              >
            </b-card-header>
          </b-card>
        </div>
      </div>
    </section>
    <div class="smart-trading container-fluid mt-5">
      <div class="container">
        <div class="smart-trading-box">
          <div class="title">
            <span>{{ $t('about_page.smart_trade_free') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('about_page.free') }}</span>
          </div>
          <div class="smart-button">
            <button class="start-btn">{{ $t('about_page.start') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="title">
              <span>We Accept</span>
            </div>
          </div>
          <div
            class="col-md-9 d-flex flex-wrap align-items-center justify-content-between mt-md-0 mt-3"
          >
            <div class="item">
              <img src="@assets/img/Skill.png" alt="skrill" />
            </div>
            <div class="item">
              <img src="@assets/img/visa.png" alt="visa" />
            </div>
            <div class="item">
              <img src="@assets/img/mc.png" alt="master card" />
            </div>
            <div class="item">
              <img src="@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="item">
              <img src="@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'terms',
  data: () => {
    return {
      faqs_check: null,
    }
  },
  computed: {
    ...mapState('Faq', ['faq']),
    faqs_check_obj() {
      return this.faqs_check
    },
  },
  methods: {
    ...mapActions('Faq', ['getFaqs']),
    setCheck(id) {
      this.faqs_check[id] = !this.faqs_check[id]
    },
  },

  mounted() {
    this.getFaqs().then(({ data }) => {
      const { docs } = data
      this.faqs_check = docs.reduce((acc, e) => {
        acc[e._id] = false
        return acc
      }, {})
    })
  },
}
</script>

<style lang="scss">
.smart-trading {
  background-image: url('./img/reviewSmartBG.png');
  background-repeat: no-repeat;
  padding: 109px 0;
  .smart-trading-box {
    text-align: center;
    .title {
      span {
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 59px;
        text-transform: capitalize;
        color: #ffffff;
      }
    }

    .description {
      margin-top: 37px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }

    .smart-button {
      margin-top: 77px;
      .start-btn {
        padding: 20px 180px;
        background-color: #f1f601;
        border: 2px solid #f1f601;
        color: #181818;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        transition: 0.3s ease-out;

        &:hover {
          background-color: transparent;
          color: #fff;
        }
      }
    }
  }
}
.dropdown_wraper {
  .dropdown {
    width: 100%;
  }
  .card {
    background: transparent;
    border: none;
  }
  header {
    background: transparent;
    border: none;
  }
  button {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // justify-content: space-between;
    width: 100% !important;
    background: linear-gradient(319.76deg, #132c48 31.66%, #163c67 98.14%);
    border: 1px solid #4a7ab7;
    border-radius: 30px;
    .button_content {
      display: flex;
      align-items: center;
    }
    p {
      padding: 0;
      margin: 0;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      margin-left: 30px;
    }
    svg {
      position: absolute;
      right: 30px;
      fill: #ffffff;
    }
    .quote {
    }
    .card-body {
      padding-left: 58px;
      @media screen and (max-width: 766px) {
        padding-left: 0 !important;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        color: #a9c7ef;
      }
    }
  }

  ul {
    width: 100%;
  }
}
.img_bg {
  top: 750px;
  left: 0;
  width: 99vw;
}
.cursor_pointer {
  cursor: pointer;
}
.title_text {
  h1 {
    font-weight: 800;
    font-size: 80px;
    line-height: 98px;
  }
  &:nth-child(1) {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #f1f601;
  }
}
.mobile-cards {
  padding: 43px 0 62px 0;
  background-color: #0d223c;
  display: flex;
  .title {
    text-align: center;
    span {
      font-size: 30px;
      font-weight: 800;
      color: #fff;
    }
  }

  .first-list-item {
    display: flex;
    justify-content: space-between;
    .item {
      img {
        width: 100%;
      }
    }
  }

  .second-list-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.setcion_img {
  height: 750px;
  background: url('~@assets/img/terms.jpg') no-repeat center;
  background-size: cover;
  h1 {
    font-weight: 800;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #f1f601;
  }
}
.definitions {
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    text-transform: capitalize;
    color: #f1f601;
  }
  ul {
    list-style: none;
    li {
      p {
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #eef0f3;
      }
    }
  }
}
.card_doted {
  border: 1px dashed #f1f601;
  box-sizing: border-box;
  border-radius: 20px;
  h5 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    color: #f1f601;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
  }
}
.btn_read {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background: transparent;
  color: #ffffff;
  border: 2px solid #f1f601;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #f1f601;
  }
}
</style>
